<template>
  <v-card :flat="!references.title ? true : false">
    <v-form ref="validateForm" autocomplete="off">
      <v-card-title primary-title  class="px-4 py-3" v-if="references.title || references.hideTitle"
      :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }; color: ${ $vuetify.theme.dark ? '' : (systemDetails.textcolor || '#fff') }`">
        <v-btn class="mr-2" text color="primary" v-if="references.showBackBtn"  small @click="$router.push('/settings')">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <h5> {{ references.title }} </h5>
        <v-spacer></v-spacer>
         <v-btn icon v-show="!references.hideCloseButton" title="Close" :color="$vuetify.theme.dark ? '' : 'white'" @click="$emit('closeDialog')">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
        <!-- <v-btn fab dark x-small color="info" class="mr-1" v-if="references.isModal" @click="references.fromApp ? references.appMinimizeHandler() : references.minimizeHandler()">
          <v-icon color="white">mdi-window-minimize</v-icon>
        </v-btn>
        <v-btn fab dark x-small color="red" v-if="references.isModal" @click="references.fromApp ? references.appCloseHandler() : references.closeHandler()">
          <v-icon color="white">mdi-window-close</v-icon>
        </v-btn> -->
      </v-card-title>
      <v-divider v-if="references.title"></v-divider>
        <v-container grid-list-xl>
        <v-layout wrap class="pa-3">
          <slot name="salesproduct"></slot>
          <template v-for="(prop, index) in references.properties">
            <v-flex :class="prop.class" :key="index" :style="prop.style" v-if="prop.is_visible !== undefined ? prop.is_visible : true">
              <template v-if="prop.slot">
                <slot :name="prop.slot" :prop="prop" :index="index"></slot>
              </template>
              <template v-if="prop.type === formType.TEXT">
                <v-text-field outlined hide-details :label="prop.label" :suffix="prop.suffix" :id="prop.model" :ref="prop.ref" @input="prop.input" @change="prop.change ? prop.change() : ''" :disabled="prop.disabled" :rules="prop.rules" v-model="model[prop.model]" dense> </v-text-field>
              </template>
              <template v-if="prop.type === formType.NUMBER">
                <v-text-field outlined hide-details :type="formType.NUMBER" :id="prop.model" :label="prop.label" :rules="prop.rules" v-model="model[prop.model]" :disabled="prop.disabled" dense> </v-text-field>
              </template>
              <template v-else-if="prop.type === formType.TEXTAREA">
                <v-textarea outlined hide-details :label="prop.label" v-if="prop.is_show !== undefined ? prop.is_show : true " :id="prop.model"
                  :rules="prop.rules" v-model="model[prop.model]" :disabled="prop.disabled" :rows="prop.rows" dense  @change="prop.change ? prop.change() : ''"></v-textarea>
              </template>
              <template v-else-if="prop.type === formType.CHECKBOX">
                <v-checkbox :label="prop.label" :id="prop.model" color="primary" :rules="prop.rules" v-model="model[prop.model]" @change="prop.click ? prop.click(prop.model): ''"  hide-details> </v-checkbox>
              </template>
              <template v-else-if="prop.type === formType.AUTO_COMPLETE">
                <v-autocomplete outlined hide-details :items="prop.items" :id="prop.model" :disabled="prop.disabled" dense
                  :loading="prop.loading"
                  :rules="prop.rules" :item-text="prop.select_text" :multiple="prop.multiple" autocomplete="off"
                  @click:append="prop.click(prop.term)"
                  :item-value="prop.select_value" :label="prop.label"
                  :ref="prop.term || prop.ref || ''"
                  :append-icon="prop.is_list ? 'add_circle' : ''"
                  clearable
                  @keyup="prop.hasLiveSearch ? handleSearchInput(prop) : ''"
                  @change="prop.change ? prop.change() : ''"
                  @keydown="prop.keydown ? prop.keydown($event) : ''"
                  v-model="model[prop.model]"
                  >
                  <template v-slot:append-item>
                    <div v-intersect="onIntersect" class="pa-0 teal--text"/>
                  </template>
                  <template v-slot:selection="{ item, index }" v-if="prop.multiple">
                    <span v-if="index === 0">{{ item[prop.select_text] }}</span>
                    <span
                      v-if="index === 1"
                      class="grey--text caption"
                    >(+{{ model[prop.model].length - 1 }} {{ $t('others') }})</span>
                  </template>
                </v-autocomplete>
              </template>
              <template v-else-if="prop.type === formType.AUTO_COMPLETE_LIVE">
                <v-autocomplete outlined hide-details :items="prop.items" :id="prop.model" :disabled="prop.disabled" dense
                  :rules="prop.rules" :item-text="prop.select_text" :multiple="prop.multiple" autocomplete="off"
                  :item-value="prop.select_value" :label="prop.label"
                  clearable
                  @change="prop.change ? prop.change() : ''"
                  @keydown="prop.keydown ? prop.keydown($event) : ''"
                  v-model="model[prop.model]"
                  :search-input.sync="model[prop.live_search_model]"
                  :loading="prop.loading"
                  >
                  <template v-slot:selection="{ item, index }" v-if="prop.multiple">
                    <span v-if="index === 0">{{ item[prop.select_text] }}</span>
                    <span
                      v-if="index === 1"
                      class="grey--text caption"
                    >(+{{ model[prop.model].length - 1 }} {{ $t('others') }})</span>
                  </template>
                </v-autocomplete>
              </template>
              <template v-else-if="prop.type === formType.SELECT">
                <v-select outlined hide-details :items="prop.items" :id="prop.model" :rules="prop.rules" :item-text="prop.select_text" dense :multiple="prop.multiple" :item-value="prop.select_value" :label="prop.label" :disabled="prop.disabled" v-model="model[prop.model]"></v-select>
              </template>
              <template v-else-if="prop.type === formType.PASSWORD">
                <v-text-field outlined hide-details :label="prop.label" :id="prop.model" :rules="prop.rules" :type="formType.PASSWORD" v-model="model[prop.model]" dense> </v-text-field>
              </template>
              <template v-else-if="prop.type === formType.COMBOBOX" class="pt-2">
                <div class="combo">
                  <v-combobox v-model="model[prop.model]" :items="prop.items" :item-text="prop.select_text" :item-value="prop.select_value"  hide-selected  :rules="prop.rules" :label="prop.label" multiple persistent-hint small-chips :id="prop.model" :disabled="prop.disabled" @keyup="prop.keyup" outlined dense hide-details></v-combobox>
                </div>
              </template>
              <template v-else-if="prop.type === formType.DATEPICKER">
                <v-menu :close-on-content-click="false" v-model="model[prop.menu]" transition="scale-transition" offset-y min-width="290px" v-if="prop.is_show !== undefined ? prop.is_show : true">
                  <template #activator="{ on: datePicker, attrs }">
                    <v-text-field outlined hide-details v-model="model[prop.model]" :rules="prop.rules" clearable :label="prop.label" dense
                    readonly :disabled="prop.disabled" @click:clear="$nextTick(() => model[prop.picker] = null)"
                    @focus="model[prop.picker] = $formatter.formatDate(model[prop.model], userDetails.dateformat, 'YYYY-MM-DD')">
                    <template #prepend>
                      <v-btn icon color="primary" v-bind="attrs" v-on="datePicker" class="ma-n1 mr-n2" @click="model[prop.picker] = $formatter.formatDate(model[prop.model], userDetails.dateformat, 'YYYY-MM-DD')">
                        <v-icon> mdi-calendar </v-icon>
                      </v-btn>
                    </template>
                    </v-text-field>
                  </template>
                  <v-date-picker no-title :color="prop.color" @input="model[prop.menu] = false; model[prop.model] = $formatter.formatDate(model[prop.picker], 'YYYY-MM-DD', userDetails.dateformat)"
                  :min="model[prop.min]" :max="model[prop.max]" v-model="model[prop.picker]" first-day-of-week='1' :disabled="prop.disabled" @change="prop.change ? prop.change(): ''"></v-date-picker>
                </v-menu>
              </template>
              <!--Date picker for sales product-->
              <!-- <template v-else-if="prop.type === formType.DATEPICKER">
                <v-menu :close-on-content-click="false" v-model="model[prop.menu]" transition="scale-transition" offset-y min-width="290px" v-if="prop.is_show">
                  <template v-slot:activator="{ on }">
                    <v-text-field outlined hide-details v-model="model[prop.model]" :rules="prop.rules" clearable :label="prop.label" prepend-icon="event"
                    readonly v-on="on" :disabled="prop.disabled" @click:clear="$nextTick(() => model[prop.picker] = null)" dense></v-text-field>
                  </template>
                  <v-date-picker :color="prop.color" @input="model[prop.menu] = false" :min="model[prop.min]" :max="model[prop.max]" v-model="model[prop.picker]" first-day-of-week='1' :disabled="prop.disabled"></v-date-picker>
                </v-menu>
              </template> -->
              <template v-else-if="prop.type === formType.QUICKADD">
                <v-btn fab small outline color="primary" :id="prop.type" @click="$router.push(prop.to)" :disabled="prop.disabled">
                  <v-icon color="primary">perm_identity</v-icon>
                </v-btn>
              </template>
              <template v-else-if="prop.type === formType.TIMEPICKER">
                <v-text-field  outlined hide-details slot="activator" :label="prop.label" :rules="prop.rules" dense v-model="model[prop.model]"
                  clearable :disabled="prop.disabled" @blur="validateTime(model[prop.model], prop.model)">
                  <template v-slot:append>
                    <v-menu :close-on-content-click="false" :ref="prop.menu" v-model="model[prop.menu]" transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-clock-outline</v-icon>
                      </template>
                      <v-time-picker color="primary" v-model="model[prop.model]" :min="model[prop.min]" :max="model[prop.max]" @click:minute="$refs[prop.menu][0].save(model[prop.model])" :allowed-minutes="allowedStep" format="24hr" :disabled="prop.disabled"></v-time-picker>
                    </v-menu>
                  </template>
                </v-text-field>
                <!-- <v-menu :close-on-content-click="false" :ref="prop.menu" v-model="model[prop.menu]" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field  outlined hide-details slot="activator" :label="prop.label" :rules="prop.rules" dense v-model="model[prop.model]" readonly v-on="on" prepend-icon="mdi-calendar" clearable :disabled="prop.disabled"></v-text-field>
                  </template>
                  <v-time-picker color="primary" v-model="model[prop.model]" :min="model[prop.min]" :max="model[prop.max]" @click:minute="$refs[prop.menu][0].save(model[prop.model])" :allowed-minutes="allowedStep" format="24hr" :disabled="prop.disabled"></v-time-picker>
                </v-menu> -->
              </template>
              <template v-else-if="prop.type === formType.FILES && prop.is_show">
                <v-file-input :accept="prop.accept" :disabled="prop.disabled" outlined v-model="model[prop.model]" :rules="prop.rules" :label="prop.label" :multiple="prop.multiple" dense
                :hide-details="prop.hideDetails"></v-file-input>
                <slot :name="prop.slot"></slot>
              </template>
              <template v-else-if="prop.type === formType.COLORPICKER">
                <v-menu bottom :close-on-content-click="false" offset-y v-model="model[prop.menu]">
                  <template v-slot:activator="{ on }">
                    <v-text-field :label="prop.label" v-model="model[prop.model]" outlined hide-details class="ma-0 pa-0" readonly slot="activator" v-on="on" :disabled="prop.disabled" dense></v-text-field>
                  </template>
                  <v-color-picker v-if="[prop.menu]" v-model="model[prop.model]" bottom flat :disabled="prop.disabled" />
                </v-menu>
              </template>
              <template v-else-if="prop.type === formType.RADIO && prop.is_show">
                <v-radio-group v-model="model[prop.model]" row @change="prop.click ? prop.click(prop.model): ''">
                  <template v-for="items in prop.items">
                    <v-radio :label="items.text" :value="items.value" :key="items.value"></v-radio>
                  </template>
                </v-radio-group>
              </template>
              <template v-else-if="prop.type === formType.DIVIDER">
                <v-divider></v-divider>
              </template>
              <template v-else-if="prop.type === formType.HEADING">
                <h3>{{prop.text}}</h3>
              </template>
              <template v-else-if="prop.type === formType.FOOTERINFORMATION && prop.is_show">
                <div class="font-weight-bold">{{prop.text}}</div>
              </template>
              <template v-else-if="prop.type === formType.HTMLFIELD">
                <html-editor v-model="model[prop.model]" :label="prop.label"></html-editor>
              </template>
              <template  v-else-if="prop.type === formType.DATE_TIME_PICKER">
                <v-menu v-model="model[prop.menu]" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                  <template  #activator="{ on }">
                    <v-text-field slot="activator" v-model="model[prop.model]" :id="`_${prop.model}`" clearable
                      :label="prop.label" prepend-icon="mdi-timetable" v-on="on" outlined dense hide-details
                      :rules="prop.rules"
                    ></v-text-field>
                  </template>
                  <v-tabs v-model="prop.active" color="primary lighten-1" dark centered slider-color="white">
                    <v-tab ripple>
                      <v-icon color="white" class="pr-2">mdi-calendar</v-icon>
                    </v-tab>
                    <v-tab ripple>
                      <v-icon color="white" class="pr-2">mdi-clock-outline</v-icon>
                    </v-tab>
                    <v-tab-item>
                      <v-date-picker v-model="model[prop.dateTimePicker]" color="primary lighten-1" no-title @change="$set(prop, 'active', 1);$forceUpdate()" :id="`_${prop.model}`" ></v-date-picker>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-time-picker format="24hr" :close-on-content-click="false" v-model="model[prop.timePicker]" color="primary lighten-1"
                          @change="model[prop.menu] = false; model[prop.model] = $formatter.formatDateTimeForPicker(model[prop.dateTimePicker], model[prop.timePicker])">
                        </v-time-picker>
                      </v-card>
                    </v-tab-item>
                  </v-tabs>
                </v-menu>
              </template>
            </v-flex>
          </template>
        </v-layout>
      </v-container>
       <v-card-text class="pa-0">
        <slot name="extraDetails"></slot>
      </v-card-text>
      <v-divider v-if="references.title"></v-divider>
      <v-card-actions v-if="references.buttons && !references.hideCard">
        <v-layout justify-center>
          <v-flex class="text-center">
            <template v-for="(button, index) in references.buttons">
              <v-btn :key="index" :loading="button.loading" :id="button.name" v-if="button.is_show" @click="button.click" :color="button.color" :disabled="button.disabled"
                class="mr-2 mt-1 text-capitalize py-4" small>
                {{ button.label }}
              </v-btn>
            </template>
          </v-flex>
        </v-layout>
      </v-card-actions>
      <slot name="button" v-else></slot>
      <slot name="extraButtons"></slot>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    references: {
      type: Object
    },
    model: {
      type: Object
    }
  },
  components: {
    'html-editor': () => import('@/components/TextEditor.vue')
  },
  data () {
    return {
      skip: 0,
      searchValue: '',
      searchTerm: ''
    }
  },
  computed: {
    ...mapGetters(['formType', 'userDetails'])
  },
  methods: {
    allowedStep: m => m % 5 === 0,
    validateTime (time, modelName) {
      if (time) {
        if (((time.includes(':') || time.includes(',')) && time.length > 5) || (!time.includes(':') && !time.includes(',') && time.length > 4)) {
          this.model[modelName] = null
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'invalidTime' })
          return false
        } else {
          if (time.includes(',')) {
            const replacedTime = time.replace(',', ':')
            this.model[modelName] = replacedTime
          } else if (!time.includes(':')) {
            const result = time.slice(0, 2) + ':' + time.slice(2)
            this.model[modelName] = result
          }
          if (!(/^(([[0|1]\d)|(2[0-3]))[:]?([0-5]\d)$/.test(this.model[modelName]))) {
            this.model[modelName] = null
            this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'invalidTime' })
            return false
          }
        }
      }
    },
    handleSearchInput (prop) {
      const searchTermRefKey = prop.term || prop.ref
      if (searchTermRefKey && this.$refs[searchTermRefKey] && this.$refs[searchTermRefKey].length) {
        const searchTerm = this.$refs[searchTermRefKey][0].lazySearch
        this.searchValue = searchTerm
        this.skip = 0
        this.$root.$emit('taskProjectId', this.skip, this.searchValue)
      }
    },
    onIntersect () {
      this.skip += 30
      this.$root.$emit('taskProjectId', this.skip, this.searchValue)
    }
  }
}
</script>
<style scoped>
.container.grid-list-xl .layout .flex {
  padding: 7px !important;
}
</style>
